<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <CampaignToolbar
        v-if="Object.keys(campaign).length !== 0"
        :campaign="campaign"
        closing
      />
      <hr>
      <div class="table-filters d-flex justify-content-between align-items-center mb-25">
        <div>
          <h4>{{ $t('Postback') }}</h4>
        </div>
        <div class="col-picker">
          <div class="d-flex align-items-center">
            <feather-icon
              icon="CalendarIcon"
              size="16"
            />
            <flat-pickr
              v-model="rangePicker"
              :config="{ mode: 'range'}"
              class="form-control flat-picker bg-transparent border-0 shadow-none"
              placeholder="YYYY-MM-DD"
              @on-close="toggleRange"
            />
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div v-else-if="rows.length">
        <b-table
          small
          :items="rows"
          :fields="filterFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          responsive
          striped
          foot-clone
        >
          <template #head(site_name)>
            {{ $t('reportsTableHeaders.sites.title') }}
          </template>

          <template #head(site_id)>
            site ID
          </template>

          <template #head(postbacks)>
            {{ $t('reportsTableHeaders.postback.amount') }}
          </template>

          <template #cell(site_id)="data">
            {{ data.value }}
          </template>

          <template #cell(postbacks)="data">
            {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
          </template>

          <template #foot(site_name)="data">
            <strong :data-label="data.label">{{ $t('Records') }}: {{ rows.length }}</strong>
          </template>

          <template #foot(site_id)="data">
            <strong :data-label="data.label" />
          </template>

          <template #foot(postbacks)="data">
            <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('postbacks')) }}</strong>
          </template>
        </b-table>
      </div>
      <div
        v-else
        class="card-nodata"
      >
        <div class="card-body">
          {{ $t('sites.nodata_period') }}
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard,
  BTable,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import flatPickr from 'vue-flatpickr-component'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import CampaignToolbar from '@/views/dashboard/campaigns/CampaignToolbar.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    Breadcrumbs,
    BCard,
    BTable,
    flatPickr,
    BSpinner,
    UserToolbar,
    CampaignToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      authUserData: getUserData(),
      loading: true,
      account: {},
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      typeStats: 'custom',
      campaign: [],
      rows: [],
      banner: [],
      fields: [
        { key: 'site_name', label: 'Name' },
        { key: 'site_id', label: 'site_id' },
        { key: 'postbacks', label: 'postback2' },
      ],
      sortBy: 'postbacks',
      sortDesc: true,
      sortDirection: 'asc',
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateTime,
      rangePicker: [getFormattedDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1)), getFormattedDate(new Date())],
    }
  },
  computed: {
    totalAmount() {
      let totalAmount = 0
      this.rows.forEach(i => {
        totalAmount += i.amount
      })
      return totalAmount
    },
    filterFields() {
      let { fields } = this
      if (!this.authUserData.role.includes('admin')) {
        fields = fields.filter(f => f.key !== 'site_name')
      }
      return fields
    },
    selectedRangeFormated() {
      let dateFrom
      let dateTo
      const selectedRange = this.rangePicker
      if (Array.isArray(selectedRange)) {
        [dateFrom, dateTo] = selectedRange
      } else {
        [dateFrom, , dateTo] = selectedRange.split(' ')
      }

      return {
        from: getFormattedDate(new Date(dateFrom)),
        to: dateTo === undefined ? getFormattedDate(new Date(dateFrom)) : getFormattedDate(new Date(dateTo)),
      }
    },
  },
  created() {
    this.getUserData()
    this.postbackConversion(true)
  },
  methods: {
    totalFormat(field) {
      let total = 0
      this.rows.forEach(i => {
        total += i[field]
      })
      return total
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.getCampaignData()
    },
    async getCampaignData() {
      const responseData = await useJwt.getCampaign(this.$route.params.id)
      this.campaign = responseData.data.campaign || []
      this.setBreabcrumbs()
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('Users'),
          to: '/users',
        },
        {
          text: this.pageTitle,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: 'campaigns',
          to: `/users/${this.$route.params.userId}/campaigns`,
        },
        {
          text: this.campaign.title,
          to: `/users/${this.$route.params.userId}/campaigns/${this.$route.params.id}`,
        },
        {
          text: 'postback',
          active: true,
        },
      ]
    },
    toggleRange(e) {
      this.rangePicker = e
      this.postbackConversion()
    },
    async postbackConversion(start = false) {
      this.loading = true
      let { from: dateFrom, to: dateTo } = this.selectedRangeFormated
      this.rows = null

      if (start) {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const urlParams = Object.fromEntries(urlSearchParams.entries())
        if (urlParams.type) {
          this.typeStats = urlParams.type
        }
        if (urlParams.from) {
          dateFrom = urlParams.from
        }
        if (urlParams.to) {
          dateTo = urlParams.to
        }
        this.rangePicker = [dateFrom, dateTo]
      }

      this.$router.replace({
        name: 'dashboard-campaign-postback',
        query: { type: this.typeStats, from: dateFrom, to: dateTo },
      }).catch(() => {})

      const params = {
        from: dateFrom,
        campaign_id: this.$route.params.id,
        to: dateTo || dateFrom,
        type: this.typeStats,
      }

      const responseData = await useJwt.getCampaignPostbackConversion(params)
      this.rows = responseData.data.campaignpostbackstats || []
      this.loading = false
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
  .table-filters {
    display: flex;
    justify-content: space-between;
  }

  tfoot th {
    background-color: #fff!important;
  }

  .col-picker {
    min-width: 220px;
  }

  @media (max-width: 1400px) {
    .table-filters {
      flex-wrap: wrap;
      margin-bottom: 15px;
    }

    .col-type {
      width: 60%;
    }
  }

  @media (max-width: 992px) {
    .col-type {
      width: 70%;
    }
  }

  @media (max-width: 800px) {
    .col-type {
      width: 100%;
    }
  }
</style>
